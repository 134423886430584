<template>
  <div>
    <div class="dropdown">
      <button type="button" class="btn btn-secondary btn-sm dropdown-toggle" data-bs-toggle="dropdown">
        <span class="mdi mdi-menu"></span>
      </button>
      <ul class="dropdown-menu">
        <li><h5 class="dropdown-header">{{ partner.id }}</h5></li>
        <li class="dropdown-divider"></li>
        <li><a class="dropdown-item" href="javascript:void(0)" @click="openDeposit(partner)">{{ $t('충전') }}</a></li>
        <li><a class="dropdown-item" href="javascript:void(0)" @click="openWithdraw(partner)">{{ $t('환전') }}</a></li>
        <li class="dropdown-divider" v-if="partner.level !== 'shop'"></li>
        <li v-if="partner.level !== 'shop'"><a class="dropdown-item" href="javascript:void(0)" @click="addChildren(partner)">{{ $t('하부 생성') }}</a></li>
        <li><a class="dropdown-item" href="javascript:void(0)" @click="openModify(partner)">{{ $t('정보 수정') }}</a></li>
        <li class="dropdown-divider"></li>
        <li><router-link :to="'/partner/detail/' + partner.id" class="dropdown-item" target="_blank">{{ $t('자세히') }}</router-link></li>
        <template v-if="$store.getters['auth/user'].type === 'admin'">
          <li class="dropdown-divider"></li>
          <li><a class="dropdown-item text-primary" href="javascript:void(0)" @click="changeState(partner, 'normal')" v-if="partner.state !== 'normal'">{{ $t('정상') }}</a></li>
          <li><a class="dropdown-item text-warning" href="javascript:void(0)" @click="changeState(partner, 'suspend')" v-if="partner.state !== 'suspend'">{{ $t('차단') }}</a></li>
          <li><a class="dropdown-item text-danger" href="javascript:void(0)" @click="changeState(partner, 'unregister')" v-if="partner.state !== 'unregister'">{{ $t('탈퇴') }}</a></li>
          <li class="dropdown-divider"></li>
          <li><a href="javascript: void(0)" class="dropdown-item text-danger" @click="deleteChildren(partner)">{{ $t('완전 삭제') }}</a></li>
        </template>
      </ul>
    </div>

    <!-- 하부 생성 //-->
    <AddChildren ref="modal-add-children" @searchList="refresh" />

    <!-- 충전 //-->
    <Deposit ref="modal-deposit" @refresh="searchList" />

    <!-- 환전 //-->
    <Withdraw ref="modal-withdraw" @refresh="searchList" />

    <!-- 정보수정 //-->
    <modify ref="modal-modify" @searchList="searchList"></modify>
  </div>
</template>

<script>
import Deposit from "../bank/deposit"
import Withdraw from '../bank/withdraw'
import AddChildren from './add-children'
import Modify from '@/components/partner/modify'

export default {
  props: {
    partner: { type: Object }
  },
  components: {
    Deposit,
    Withdraw,
    AddChildren,
    Modify
  },
  methods: {
    changeState: async function(partner, state) {
      if (!confirm(`${partner.username}을 ${state.toUpperCase()}하시겠습니까?`)) {
        return
      }

      const loader = this.$loading.show({ container: this.$refs.contentWrapper })
      try {
        const result = await this.$API.user.changeState(partner.id, state)
        if (result.code !== 0) {
          return alert(result.msg)
        }

        this.searchList()
      } catch(e) {
        alert(this.$t('text.error_post'))
        this.$log.error(e)
      } finally {
        loader.hide()
      }
    },
    searchList: function() {
      this.$emit('sl')
    },
    refresh: function() {
      this.$emit('rf')
    },
    openModify: function(partner) {
      this.$refs['modal-modify'].open(partner)
    },
    openDetail: function(id) {
      // console.log(Detail, id)
      this.$refs['modal-partner-detail'].open(id)
    },
    openDeposit: function(user) {
      this.$refs['modal-deposit'].open(user)
    },
    openWithdraw: function(user) {
      this.$refs['modal-withdraw'].open(user)
    },
    openManageVendors: function(user) {
      this.$refs['modal-manage-vendors'].open(user)
    },
    addChildren: function(parent) {
      this.$refs['modal-add-children'].open(parent)
    },
    deleteChildren: async function(partner) {
      if (!confirm(`${partner.username}을 완전 삭제하시겠습니까?`)) {
        return
      }

      const loader = this.$loading.show({ container: this.$refs.contentWrapper })
      try {
        const result = await this.$API.user.delete(partner.id)
        if (result.code !== 0) {
          return alert(result.msg)
        }

        this.searchList()
      } catch(e) {
        alert(this.$t('text.error_post'))
        this.$log.error(e)
      } finally {
        loader.hide()
      }
    }
  },
  computed: {

  }
}
</script>
