<script>

export default {
  components: {

  },
  data() {
    return {
      parent: null,
      openDialog: false,
      params: {
        parent: '',
        prefix: '',
        username: '',
        password: '',
        passwordConfirm: '',
        nickname: '',
        cashRate: 0,
        language: 'ko',
        currency: 'KRW',
        timezone: 9,
        useSeamless: false,
        useEvo: '',
        detailCallbackUrl: '',
        transferCallbackUrl: {
          balance: ''
        },
        seamlessCallbackUrl: {
          authenticate: '',
          balance: '',
          bet: '',
          result: '',
          cancel: ''
        },
        memo: ''
      }
    };
  },
  mounted() {

  },
  methods: {
    open: function(parent) {
      this.openDialog = true

      // 데이터 초기화
      this.parent = parent

      this.params.prefix = ''
      this.params.username = ''
      this.params.password = ''
      this.params.passwordConfirm = ''
      this.params.nickname = ''
      this.params.cashRate = 0
      this.params.language = 'ko'
      this.params.currency = 'KRW'
      this.params.timezone = 9
      this.params.useSeamless = false
      this.params.level= ''
      this.params.email = ''
      this.params.detailCallbackUrl = ''
      this.params.transferCallbackUrl.balance = ''
      this.params.useSeamless = 0
      this.params.seamlessCallbackUrl.authenticate = ''
      this.params.seamlessCallbackUrl.balance = ''
      this.params.seamlessCallbackUrl.bet = ''
      this.params.seamlessCallbackUrl.result = ''
      this.params.seamlessCallbackUrl.cancel = ''
      this.params.memo = ''
      this.params.useEvo = 0

      if (parent.type === 'partner' && parent) {
        if (this.parent.level === 'top') {
          this.params.level = 'super'
        } else if (this.parent.level === 'super') {
          this.params.level = 'master'
        } else if (this.parent.level === 'master') {
          this.params.level = 'agent'
        } else if (this.parent.level === 'agent') {
          this.params.level = 'shop'
        }

        this.params.parent = parent.id

        // 에볼루션 스위치 = 컴퍼니가 아니라면 상부의 값을 상속받는다.
        this.params.useEvo = parent.useEvo

      } else {
        this.params.level = 'top'
      }
    },
    register: async function() {
      const loader = this.$loading.show({ container: this.$refs.addChildrenFormWrapper })
      try {
        const result = await this.$API.partner.create(this.params)
        if (result.code !== 0) {
          return alert(result.msg)
        }

        this.openDialog = false

        this.$emit('searchList')

      } catch (e) {
        alert(this.$t('text.error_post'))
        this.$log.error(e)
      } finally {
        loader.hide()
      }
    },
  }
};
</script>

<template>
  <div ref="addChildrenFormWrapper">
    <b-modal
        :title="`${$t('하부 생성')}`"
        v-model="openDialog"
        size="lg"
        scrollable="true"
        centered="true"
        button-size="sm"
        hide-footer="true"
    >
      <form @submit.prevent="register">
        <div class="row">
          <div class="col-md-6">
            <div class="form-floating mb-3">
              <input type="text" class="form-control" :placeholder="'Prefix(회원 아이디 접두어이며 \'_\'가 자동을 붙습니다.)'" v-model="params.prefix" />
              <label class="form-label">Prefix(회원 아이디 접두어이며 '_'가 자동으로 붙습니다.)</label>
            </div>

            <div class="form-floating mb-3">
              <input type="text" class="form-control" :placeholder="$t('로그인 아이디')" v-model="params.username" />
              <label class="form-label">{{ $t('로그인 아이디') }}</label>
            </div>

            <div class="form-floating mb-3">
              <input type="password" class="form-control" :placeholder="$t('비밀번호')" v-model="params.password" />
              <label class="form-label">{{ $t('비밀번호') }}</label>
            </div>

            <div class="form-floating mb-3">
              <input type="password" class="form-control" :placeholder="$t('비밀번호 확인')" v-model="params.passwordConfirm" />
              <label class="form-label">{{ $t('비밀번호 확인') }}</label>
            </div>

            <div class="form-floating mb-3">
              <input type="text" class="form-control" :placeholder="$t('별칭')" v-model="params.nickname" />
              <label class="form-label">{{ $t('별칭') }}</label>
            </div>

            <div class="form-floating mb-3">
              <input type="text" class="form-control" :placeholder="`${$t('메모')}`" maxlength="250" v-model="params.memo" />
              <label class="form-label">{{ `${$t('메모')}` }}</label>
            </div>

          </div>
          <div class="col-md-6">
            <div class="form-floating mb-3">
              <input type="number" class="form-control" :placeholder="$t('요율')" v-model="params.cashRate" step="0.1" />
              <label class="form-label">{{ $t('요율') }}(%)</label>
            </div>

            <div class="form-floating mb-3">
              <input type="text" class="form-control" :placeholder="`${$t('이메일')}(${$t('text.register_email_desc')})`" v-model="params.email" />
              <label class="form-label">{{ `${$t('이메일')}(${$t('text.register_email_desc')})` }}</label>
            </div>

            <div class="form-floating mb-3">
              <select class="form-select" :placeholder="$t('언어')" v-model="params.language">
                <option value="ko">한국어</option>
                <!--
                <option value="en">English</option>
                //-->
              </select>
              <label class="form-label">{{ $t('언어') }}</label>
            </div>

            <div class="form-floating mb-3">
              <select class="form-select" :placeholder="$t('통화')" v-model="params.currency">
                <option value="KRW">KRW</option>
                <!--
                <option value="USD">USD</option>
                <option value="CNY">CNY</option>
                //-->
              </select>
              <label class="form-label">{{ $t('통화') }}</label>
            </div>

            <div class="form-floating mb-3">
              <input type="number" class="form-control" :placeholder="`${$t('타임존')}`" v-model="params.timezone" />
              <label class="form-label">{{ `${$t('타임존')}` }}</label>
            </div>

            <div class="form-floating mb-3">
              <input type="text" class="form-control" :placeholder="`${$t('text.detail_callbackurl_desc')}`" maxlength="250" v-model="params.detailCallbackUrl" />
              <label class="form-label">{{ `${$t('text.detail_callbackurl_desc')}` }}</label>
            </div>
          </div>
        </div>
        <div class="row" :hidden="$store.getters['auth/user'].type !== 'admin'">
          <div class="col-md-6">
            <div class="form-floating mb-3">
              <select class="form-select" :placeholder="$t('에볼루션 스위치')" v-model="params.useEvo">
                <option :value="0">{{ $t('비활성') }}</option>
                <option :value="1">{{ $t('활성') }}</option>
              </select>
              <label class="form-label">{{ $t('에볼루션 스위치') }}</label>
            </div>
          </div>
          <div class="col-md-6">
          </div>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="form-floating mb-3">
              <select class="form-select" :placeholder="$t('지갑 형식')" v-model="params.useSeamless">
                <option :value="0">{{ $t('머니 이동') }}</option>
                <option :value="1">{{ $t('심리스') }}</option>
              </select>
              <label class="form-label">{{ $t('지갑 형식') }}</label>
            </div>
          </div>
          <div class="col-md-6">
            <template v-if="params.useSeamless === 0">
              <div class="form-floating mb-3">
                <input type="text" class="form-control" :placeholder="$t('잔액확인 콜백 URL')" v-model="params.transferCallbackUrl.balance" :disabled="params.useSeamless === 1" />
                <label class="form-label">{{ $t('잔액확인 콜백 URL') }}</label>
              </div>
            </template>
            <template v-if="params.useSeamless === 1">
              <div class="form-floating mb-3">
                <input type="text" class="form-control" :placeholder="$t('인증 콜백 URL')" v-model="params.seamlessCallbackUrl.authenticate" :disabled="params.useSeamless !== 1" />
                <label class="form-label">{{ $t('인증 콜백 URL') }}</label>
              </div>
              <div class="form-floating mb-3">
                <input type="text" class="form-control" :placeholder="$t('잔액 콜백 URL')" v-model="params.seamlessCallbackUrl.balance" :disabled="params.useSeamless !== 1" />
                <label class="form-label">{{ $t('잔액 콜백 URL') }}</label>
              </div>
              <div class="form-floating mb-3">
                <input type="text" class="form-control" :placeholder="$t('배팅 콜백 URL')" v-model="params.seamlessCallbackUrl.bet" :disabled="params.useSeamless !== 1" />
                <label class="form-label">{{ $t('배팅 콜백 URL') }}</label>
              </div>
              <div class="form-floating mb-3">
                <input type="text" class="form-control" :placeholder="$t('결과 콜백 URL')" v-model="params.seamlessCallbackUrl.result" :disabled="params.useSeamless !== 1" />
                <label class="form-label">{{ $t('결과 콜백 URL') }}</label>
              </div>
              <div class="form-floating mb-3">
                <input type="text" class="form-control" :placeholder="$t('취소 콜백 URL')" v-model="params.seamlessCallbackUrl.cancel" :disabled="params.useSeamless !== 1" />
                <label class="form-label">{{ $t('취소 콜백 URL') }}</label>
              </div>
            </template>
          </div>
        </div>
        <div class="text-end">
          <hr />
          <button type='submit' class='btn btn-primary me-1'>{{ $t('생성') }}</button>
          <button type='button' class='btn btn-secondary' data-bs-dismiss='modal'>{{ $t('닫기') }}</button>
        </div>
      </form>
    </b-modal>
  </div>
</template>
